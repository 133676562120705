import { historyAuditInfoForHomework } from '@/api/user/teacher'
import { Homework } from 'momai'
import { ref, computed } from 'vue'

export default (homeworkId: number) => {
  const historyAudits = ref<Homework[]>([])
  historyAuditInfoForHomework(homeworkId).then(r => {
    historyAudits.value = r
  })
  const hasHistories = computed(() => historyAudits.value.length > 0)
  const newestAditInfo = computed(() => {
    const histories = historyAudits.value
    if (!hasHistories.value) {
      return '暂无往期点评'
    }
    return histories[histories.length - 1].teaEva
  })
  const showHistoryAuditDialog = ref(false)
  return {
    hasHistories,
    historyAudits,
    showHistoryAuditDialog,
    newestAditInfo,
    openHistoryDialog: () => {
      showHistoryAuditDialog.value = true
    }
  }
}
