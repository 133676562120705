
import { computed, reactive, ref } from 'vue'
import { auditTags } from '@/states/tags'
import { useRoute, useRouter } from 'vue-router'
import { useFetchDetail } from '@/api/entity/homework'
import { fromNow } from '@/utils/format'
import { getUserId } from '@/utils/auth'
import { Tag } from 'momai'
import { Dialog, Notify } from 'vant'
import { uploadImage } from '@/api/universal/file'
import { audit } from '@/api/user/teacher'
import useHistoryAudit from './useHistoryAudit'
import { api } from '@/api/useAxios'

interface FilePreviewItem {
  url: string;
}

export default {

  setup: () => {
    const router = useRouter()
    const { homeworkId } = useRoute().params
    const auditForm = reactive({
      id: Number(homeworkId),
      unread: 0,
      teaPic: '',
      teaEva: '',
      status: '2',
      teaVoice: '',
      rsrv1: '0'
    })

    const fileList = ref<FilePreviewItem[]>([])

    const homework = ref<any>()
    const qrywork = () => {
      api.post('/homework/getHomeworkDetailNew', {
        homeworkId: homeworkId
      }).then((r) => {
        homework.value = r
      })
    }
    qrywork()

    const isPublic = computed(() => homework.value.self === '0')
    const uploadDirectory = `teacher/${getUserId()}/audit-homework`
    return {
      ...useHistoryAudit(Number(homeworkId)),
      fileList,
      isPublic,
      auditTags,
      homework,
      auditForm,
      fromNow,
      uploadDirectory,
      uploadImage: ({ file }: { file: File }) => {
        uploadImage(uploadDirectory, file).then(r => {
          auditForm.teaPic = r
        })
      },
      addTag: (t: Tag) => {
        auditForm.teaEva += t.tagName
      },
      canSave: computed(() => {
        const { teaPic, teaEva } = auditForm
        return !teaPic && !teaEva
      }),
      save: () => {
        Dialog.confirm({
          message: auditForm.status === '1' ? '确认选择通过吗？' : '确认选择不通过吗？',
          title: '确认'
        }).then(() => {
          audit({
            ...auditForm,
            userId: homework.value.userId
          }).then(() => {
            Notify({
              type: 'success',
              message: '导师评价保存成功！'
            })
            router.go(-1)
          })
        }).catch(() => {})
      }
    }
  }
}
